body{
  background-color: #de6519;
}


.App {
  text-align: center;
  min-height: 100vh;
  padding:  50px;
}

.main-content
{
  margin: 0 auto;
}

.map-wrapper{
  height: 400px;
  width: 400px;
  margin: 0 auto;
}

.map-content{
  height: 400px !important;
  width: 400px !important;
}

#disqus_thread{
  margin-top: 50px;
  background-color: #3c3c3c;
  padding: 20px;
  border-radius: 20px;
}

@media screen and (max-width: 499px) {
  .App{
    padding: 25px;
  }

  #disqus_thread{
    margin-top: 25px;
  }

  .map-wrapper{
    height: 350px;
    width: 350px;
  }

  .map-content{
    height: 350px !important;
    width: 350px !important;
  }
}

@media screen and (max-width: 399px) {
  .App{
    padding: 15px;
  }

  #disqus_thread{
    margin-top: 15px;
  }

  .map-wrapper{
    height: 270px;
    width: 270px;
  }

  .map-content{
    height: 270px !important;
    width: 270px !important;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

